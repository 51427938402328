<template>
  <div class="container-fluid padding-top-layout">
    <div class="wrap">
      <div class="wrap__block wrap__block-container">
        <div class="wrap__block__header wrap__header">
          <div class="">
            <span>{{ $t("list_reduction.title_manage_reduction_target") }}</span>
            <div class="helper-icon">
              <img
                :src="getSettingIcon('helper-icon.svg')"
                @mouseover="changeTooltipImage($event)"
                @mouseleave="changeTooltipImage($event, 'mouseleave')"
                alt=""
              />
              <div v-if="showTooltip" class="helper-icon tooltip" v-html="''"></div>
            </div>
        </div>
        </div>
        <div class="wrap__status">
          <div class="wrap__status__item register-btn">
            <common-button class="return__header" v-ripple="false" @action="navigateToCreateInforReduction()" :label="labelBtnSignUp">
              <div class="register-button_warp">
                <img class="mr-0 return" src="@/assets/icons/addregistericon.svg" />
                <img class="mr-0 return-active" src="@/assets/icons/addregistericon_active.svg" />
              </div>
            </common-button>
          </div>
        </div>
      </div>
      <div
        class="category-table main-table custom-table new-register view-table"
        :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
      >
        <data-table
          @updateDatatitle="updateDataTitle"
          :data-source="listData"
          :grid-columns="gridColumns"
          :init-grid="initializeGrid"
          :rowFocus="1"
          :showFocus="false"
          :allowAddNew="false"
          :isShowToolbar="false"
          :isResizeCustome="false"
          @changeFullScreen="onChangeFullScreen"
          @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
          @flexGridInitialDone="onFlexGridInitialDone"
          class="table-reduction-target-list"
          :isEmptySource="isEmptySource"
          :labelEmpty="messageLabelEmpty"
          :tableName="'reductionTarget'"
        />
      </div>
      <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
      <history-popup :dialog="historyPopup" :historyLogList="historyLogList" @close="closePopup" />
    </div>
    <div ref="scrollElement">
      <div class="return__button-responsive" :class="{'fixed-button-bottom': isFixedButton}">
        <div class="item-responsive register-btn">
          <common-button class="return__header" v-ripple="false" @action="navigateToCreateInforReduction()">
            <div class="register-button_warp">
                <img class="mr-0 return" src="@/assets/icons/addregistericon.svg" />
                <img class="mr-0 return-active" src="@/assets/icons/addregistericon_active.svg" />
                <span>{{ $t('register_reduction.label_sign_up') }}</span>
            </div>
          </common-button>
        </div>
      </div>
    </div>
    <notification-popup :dialog="dialogNotification" :message="messageNotification" @submit="dialogNotification = false" />
    <notification-popup :dialog="showPopupDurationSuccess" :message="messageDurationSuccess" @submit="showPopupDurationSuccess = false"/>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { CollectionView } from "@mescius/wijmo";
import _ from 'lodash';
import DataTable from "@/components/category/data-table";
import { ROUTES } from "@/router/constants";
import { KEYS_CODE } from "@/constants/keyboard";
import * as wjGrid from "@mescius/wijmo.grid";
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import CommonButton from "@/components/utils/button.vue";
import HistoryPopup from "@/components/dialogs/history-popup.vue";
import CommentLogPopup from '@/components/dialogs/log-confirm-popup';
import NotificationPopup from '@/components/dialogs/notification-popup';
import { getDurations } from "@/api/duration";
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import moment from "moment";
import { updateDataBeforeRedirect } from "@/utils/updateDataBeforeRedirect";
import { REGISTER_PULLDOWN, LIST_PULLDOWN } from '@/constants/registerData';
import { $_helper_isNumberType, formatValue } from '@/concerns/newRegisterData/wijmo.helper';
import { scopeCategoriesData, newPcafMethod, oldmethod } from '@/constants/export-report';
import { getListReductionTargetApi } from "@/api/reduction-target";
import { setMinMaxSizeColumns, getWidthByTextContent } from '@/utils/calcTextWidth';
import { getListBranchApi } from "@/api/facility";
import { RestrictedMergeManager } from '@/constants/reduction';
import { TEXT_NULL } from "@/constants/dashboard";
import { handleGetScopeCategories } from '@/utils/getScopeCategories';
import { getDataTypeApi } from '@/api/pcaf'
import { setDataTypeForPcafMethod } from '@/utils/pcaf'
export default {
  name: "ListReductionTarget",
  data() {
    return {
      items           : [
        {
          text    : this.$t("list_reduction.hyperlink_home"),
          disabled: false,
          href    : ROUTES.HOME_DASHBOARD
        },
        {
          text    : this.$t("list_reduction.hyperlink_setting"),
          disabled: false,
          href    : ROUTES.SETTING
        },
        {
          text    : this.$t("list_reduction.label_manage_reduction_target"),
          disabled: false,
          href    : ROUTES.LIST_PRODUCTS
        }
      ],
      duration        : "",
      flex            : null,
      lisReductionTargetData: [],
      listData        : null,
      durationId      : null,
      undoStack       : null,
      canUndo         : false,
      canRedo         : false,
      commentPopup    : false,
      historyPopup    : false,
      gridColumns     : [],
      showTooltip     : false,
      historyLogList     : [],
      commentHistoryList: [],
      dialogNotification: false,
      messageNotification: 'データを登録する前に、設定画面より「登録期間」を先にご設定ください。',
      isRedirectDuration: false,
      disabledTemplateButton: false,
      showPopupDurationSuccess: false,
      showRegisterPulldown: false,
      isShowMethodSelect: false,
      selectedFilterColumn: null,
      organizationList: [
        { id: 1, value: this.$t('reduction.label_single_company')},
        { id: 2, value: this.$t('reduction.label_subsidiaries_company') },
        { id: 3, value: this.$t('reduction.label_associated_company') },
        { id: 4, value: this.$t('reduction.label_associated_company_other') }
      ],
      dataTitle: null,
      isEmptySource: true,
      isFixedButton: false,
      dataBranch: [],
      detailBranchList: {},
    };
  },
  components: {
    DataTable,
    CommonButton,
    CommentLogPopup,
    HistoryPopup,
    NotificationPopup
  },
  async mounted() {
    if (this.isSettingPcaf) {
      await this.handleGetDataType()
    }
    await this.updateBreadCrumb(this.items);
    this.actionUpdateIsLoadingTable(true)
    this.detailBranchList = await getListBranchApi({contractor_id: this.currentUser.user?.contractor_id});
    const convertKey = ['business_name_ids', 'country_ids', 'layer_3_ids', 'layer_4_ids', 'layer_5_ids', 'layer_6_ids']
    this.dataBranch = this.detailBranchList.layer.filter(item => item.status).sort((firstLayer, nextLayer) => firstLayer.id - nextLayer.id).map(item => ({...item, binding: convertKey[item.layer_index - 1]}));
    await Promise.all([this.getTemplateData(), this.getListDuration()]);
    this.actionUpdateIsLoadingTable(false)
    this.showPopupDurationSuccess = this.isChangedDurationSuccess;
    this.actionUpdateIsChangedDurationSuccess(false)
    setTimeout(() => {
        this.handleScroll(); // Adjust the delay as needed
    }, 1000); 
    document.addEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapState("commonApp", ["loadMore", "isFilter", "isExpand"]),
    ...mapState("registerData", ["isFullScreen",'isChangedDurationSuccess']),
    ...mapState("userData", ["currentUser", "isSettingPcaf", "planType"]),
    isAdmin() {
      return this.currentUser?.user?.role_id === 3;
    },
    registerPulldown() {
      return Object.values(REGISTER_PULLDOWN);
    },
    listPullDown() {
      return LIST_PULLDOWN
    },
    listPullDownSp() {
      return LIST_PULLDOWN.slice(0, 2)
    },
    labelBtnSignUp() {
      return this.$t("list_reduction.button_sign_up");
    },
    messageDurationSuccess() {
      return this.$t("list_reduction.description_message_duration_success");
    },
    messageLabelEmpty() {
      return this.$t("list_reduction.description_reduction_empty");
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods : {
    ...mapActions("commonApp", ["updateBreadCrumb", "actionUpdateIsLoadingTable"]),
    ...mapActions("registerData", ["updateDataScope", "updateDataCategory", "updateDataMonth", "updateDuration", "updateListDuration","actionUpdateIsChangedDurationSuccess","actionCheckIvalueDurationSuccess"]),
    ...mapActions("newRegisterData", ["updateIsApproved"]),
    async handleGetDataType() {
      try {
        const responseDataType = await getDataTypeApi()
        this.allDataType = responseDataType.data
      } catch (error) {
        console.warn(error);
      }
    },
    handleScroll() {
      setTimeout(() => {
        const scrollElementTop = this.$refs.scrollElement.offsetTop; // Vị trí của phần tử
        const scrollPosition = window.scrollY + window.innerHeight; // Vị trí scroll hiện tại
        if (scrollPosition > (scrollElementTop + 239)) { //position btn currently
          this.isFixedButton = false;
        } else {
          this.isFixedButton = true;
        }
      }, 100)
    },
    updateDataTitle(value) {
      this.lisReductionTargetData = this.lisReductionTargetData.filter(data => data.title.toLowerCase().includes(value.toLowerCase()))
      this.flex.refresh();
    },
    navigateToCreateInforReduction() {
      return this.$router.push({ path: ROUTES.CREATE_REDUCTION_TARGET });
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },
    initializeGrid(flexgrid) {
      this.flex = flexgrid;

      document.addEventListener("keydown", (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(
            event.keyCode
          )
        ) {
          event.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        "keydown",
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection     = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false
      );
      flexgrid.hostElement.addEventListener('mouseover',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      flexgrid.hostElement.addEventListener('mouseout',(e)=>{
        if(e.target.parentElement.classList.contains('comment-icon')){
        e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      this.flex.onSelectionChanged(null);
      flexgrid.beginningEdit.addHandler((s, e) => {
        let column = s.columns[e.col];
        if (column.binding !== 'data_id') {
          e.cancel = true;
        }
      });
      flexgrid.mergeManager = new RestrictedMergeManager()
    },
    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src                        = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip                        = false;
      } else {
        event.target.src                        = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip                        = false;
      }
    },
    getInfoBranch(branch, fieldName){
      switch (fieldName) {
        case 'company_name':
          const companyName = branch.map(item => (item[fieldName] + '').trim());
          const result = Array.from(new Set(companyName))?.join(', ');
          return result;
        case 'organizational_division':
          const information = branch.map(item => item[fieldName])?.join(', ');
          return information;
        default:
          const data = branch.map(item => item[fieldName] ?? this.$t("register_data.checkbox_blank"));
          return Array.from(new Set(data))?.join(', ');
      }
    },
    setDefaultLayerData(branchesList, branchDetail) {
      let defaultData = {};
      const itemData = {
        'business_name_ids': 'business_name',
        'country_ids': 'country',
        'layer_3_ids': 'layer_3',
        'layer_4_ids': 'layer_4',
        'layer_5_ids': 'layer_5',
        'layer_6_ids': 'layer_6',
      }
      this.dataBranch.forEach(item => {
        if (branchesList[item.binding].length > 0) {
          defaultData[item.binding] = this.getInfoBranch(branchesList[item.binding], itemData[item.binding]);
        } else {
          defaultData[item.binding] = [];
        }
      })
      Object.keys(defaultData).forEach(itemType => {
        if (defaultData[itemType].length === 0) {
          for (let i = 0; i < this.detailBranchList.data.length; i++) {
            const item = this.detailBranchList.data[i];
            if (!this.checkItemExist(itemType, item, branchDetail, branchesList, defaultData)) {
              continue;
            }
            switch (itemType) {
              case "business_name_ids":
                defaultData[itemType].push(item.business_name || TEXT_NULL);
              break;
              case "country_ids":
                defaultData[itemType].push(item.country || TEXT_NULL);
              break;
              case "layer_3_ids":
                defaultData[itemType].push(item.layer_3 || TEXT_NULL);
              break;
              case "layer_4_ids":
                defaultData[itemType].push(item.layer_4 || TEXT_NULL);
              break;
              case "layer_5_ids":
                defaultData[itemType].push(item.layer_5 || TEXT_NULL);
              break;
              case "layer_6_ids":
                defaultData[itemType].push(item.layer_6 || TEXT_NULL);
              break;
              default:
                break;
            }
          }
          defaultData[itemType] = this.removeDuplicateData(defaultData[itemType]);
        }
      })

      return defaultData;
    },
    checkItemExist(itemType, item, branchDetail, branchesList, defaultData) {
      const filterType = ['organizational', 'companies' ,'business_name_ids', 'country_ids', 'layer_3_ids', 'layer_4_ids', 'layer_5_ids', 'layer_6_ids'];
      const existIndex = filterType.indexOf(itemType);
      for (let i = 0; i < existIndex; i++) {
        if (!this.filterDataByIndex(filterType[i], item, branchDetail, branchesList, defaultData)) {
          return false;
        }
      }
      return true;
    },
    filterDataByIndex(type, item, branchDetail, branchesList, defaultData) {
      switch (type) {
        case "organizational":
          return branchDetail?.organization.indexOf(item.organizational_division) < 0 ? false : true;
        case "companies":
          return branchDetail?.company_name.indexOf(item.company_name) < 0 ? false : true;
        case "business_name_ids":
          if (branchesList[type].length > 0) {
            // if exist branches data
            return branchesList[type].findIndex(itemData => itemData.business_name === item.business_name) < 0 ? false: true;
          } else {
            return defaultData[type].indexOf(item.business_name) < 0 ? false: true;
          }
        case "country_ids":
          if (branchesList[type].length > 0) {
            // if exist branches data
            return branchesList[type].findIndex(itemData => itemData.country === item.country) < 0 ? false: true;
          } else {
            return defaultData[type].indexOf(item.country) < 0 ? false: true;
          }
        case "layer_3_ids":
          if (branchesList[type].length > 0) {
            // if exist branches data
            return branchesList[type].findIndex(itemData => itemData.layer_3 === item.layer_3) < 0 ? false: true;
          } else {
            return defaultData[type].indexOf(item.layer_3) < 0 ? false: true;
          }
        case "layer_4_ids":
          if (branchesList[type].length > 0) {
            // if exist branches data
            return branchesList[type].findIndex(itemData => itemData.layer_4 === item.layer_4) < 0 ? false: true;
          } else {
            return defaultData[type].indexOf(item.layer_4) < 0 ? false: true;
          }
        case "layer_5_ids":
          if (branchesList[type].length > 0) {
            // if exist branches data
            return branchesList[type].findIndex(itemData => itemData.layer_5 === item.layer_5) < 0 ? false: true;
          } else {
            return defaultData[type].indexOf(item.layer_5) < 0 ? false: true;
          }
        case "layer_6_ids":
          if (branchesList[type].length > 0) {
            // if exist branches data
            return branchesList[type].findIndex(itemData => itemData.layer_6 === item.layer_6) < 0 ? false: true;
          } else {
            return defaultData[type].indexOf(item.layer_6) < 0 ? false: true;
          }
        default:
          return true;
      }
    },
    removeDuplicateData(arr) {
      return [...new Map(arr.map((item) => [item['text'], item])).values()];
    },
    async getTemplateData() {
      getListReductionTargetApi().then((res) => {
        res.data.forEach((item) => {
        const indexScope3Category15 = scopeCategoriesData.findIndex((i) => i.categories === 15);
        let dataScope = JSON.parse(JSON.stringify([...scopeCategoriesData]))
        // dataScope[indexScope3Category15].methods = [...oldmethod, ...newPcafMethod];
        let pcafMethod = newPcafMethod
        if (this.planType?.is_pcaf && !this.allDataType.length) {
          pcafMethod = setDataTypeForPcafMethod(newPcafMethod, this.allDataType)
        }
        dataScope[indexScope3Category15].methods = this.planType?.is_pcaf ? pcafMethod : oldmethod;
          const listScopeCategories = handleGetScopeCategories(item.pattern_ids, dataScope, this.allDataType).map(item => item.text);
        listScopeCategories.forEach(scopeCategory => {
          const defaultLayerData = this.setDefaultLayerData(item.branches, {organization: this.getInfoBranch(item.branches.organizational_division, "organizational_division"), company_name: this.getInfoBranch(item.branches.company_name, "company_name")});
          this.lisReductionTargetData.push({
            id               : item.id,
            data_id          : item.id,
            title            :item.title || null,
            last_modified_by :item.updated_by_user.name || null,
            last_modified    :formatDateTimeDataTable(item.updated_at),
            start_date:this.$t("list_reduction.table_year_fiscal_standard_target", {year: item.start_year}),
            end_date:this.$t("list_reduction.table_year_fiscal_standard_target", {year: item.end_year}),
            group:
            (this.organizationList.filter(record => (this.getInfoBranch(item.branches.organizational_division, "organizational_division")).includes(record.id))).map(ele => ele.value),
            organization:this.getInfoBranch(item.branches.company_name, "company_name"),
            business_name_ids:defaultLayerData["business_name_ids"],
            country_ids:defaultLayerData["country_ids"],
            layer_3_ids:defaultLayerData["layer_3_ids"],
            layer_4_ids:defaultLayerData["layer_4_ids"],
            layer_5_ids:defaultLayerData["layer_5_ids"],
            layer_6_ids:defaultLayerData["layer_6_ids"],
            range_type: scopeCategory,
          });
        })
      });
      this.defineTable();
      });
    },
    defineTable() {
      this.gridColumns       = this.getGridColumns();
      this.listData          = new CollectionView([...this.lisReductionTargetData], {
        sortComparer: (a, b) => {
          if (this.selectedFilterColumn !== 'category') return null;
          const valueA = a?.split('カテゴリ')[1]?.trim();
          const valueB = b?.split('カテゴリ')[1]?.trim();
          if ($_helper_isNumberType(valueA) && $_helper_isNumberType(valueB)) {
            const numA = Number(formatValue(valueA));
            const numB = Number(formatValue(valueB));
            return numA - numB;
          }
          return null;
        }
      });
      this.flex.columnGroups = this.getGridColumns();
      this.listData.currentItem = null;
      this.isEmptySource = this.listData?.items?.length <= 0;
      setMinMaxSizeColumns(this.flex, this.lisReductionTargetData);
      this.$nextTick(() => {
        this.scrollToTop();
      });
    },
    getGridColumns() {
      const mapLayers = this.dataBranch.map((layer) => ({
        header      : layer.layer_name,
        binding     : layer.binding,
        minWidth    : 104,
        maxWidth    : 860,
        allowSorting: false,
        isRequired  : false,
        cssClassAll : "btn-db",
        wordWrap    : true,
        multiLine   : true,
      }));
      return [
        {
          header      : "#",
          binding     : "id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false,
        },
        {
          header      : " ",
          binding     : "data_id",
          minWidth    : getWidthByTextContent(this.$t("list_reduction.button_edit_reduction")),
          maxWidth    : getWidthByTextContent(this.$t("list_reduction.button_edit_reduction")),
          allowSorting: false,
          isRequired  : false,
          cssClassAll : "btn-db table-id hide-filter align-center",
          cellTemplate: CellMaker.makeButton({
            text : this.$t("list_reduction.button_edit_reduction"),
            click: (event, context) => this.onIdClicking(context),
          }),
          allowMerging: true,
          },
        {
          header      : this.$t("list_reduction.table_title"),
          binding     : "title",
          minWidth    : 100,
          maxWidth    : 860,
          allowSorting: false,
          isRequired  : false,
          cssClassAll : "btn-db history-btn",
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t("list_reduction.table_start_date"),
          binding     : "start_date",
          minWidth    : getWidthByTextContent(this.$t("list_reduction.table_start_date")),
          maxWidth    : 250,
          allowSorting: false,
          cssClassAll : "btn-db",
          multiLine   : true,
          wordWrap    : true,
        },
        {
          header      : this.$t("list_reduction.table_end_date"),
          binding     : "end_date",
          minWidth    : getWidthByTextContent(this.$t("list_reduction.table_end_date")),
          maxWidth    : 250,
          allowSorting: false,
          isRequired  : false,
          cssClassAll : "btn-db",
          multiLine   : false,
          wordWrap    : true,
        },
        {
          header : this.$t("list_reduction.table_organizational_intelligence"),
          align  : "center",
          collapseTo: 'group',
          columns: [
              {
                header      : this.$t("list_reduction.table_group"),
                binding     : "group",
                minWidth    : getWidthByTextContent(this.$t("list_reduction.table_organizational_intelligence")),
                maxWidth    : 860,
                allowSorting: false,
                isRequired  : false,
                cssClassAll : "btn-db",
                wordWrap    : true,
                multiLine   : true,
              },
              {
                header      : this.$t("list_reduction.table_organization"),
                binding     : "organization",
                minWidth    : 104,
                maxWidth    : 860,
                allowSorting: false,
                isRequired  : false,
                cssClassAll : "btn-db",
                wordWrap    : true,
                multiLine   : true,
              },
              ...mapLayers,
          ],
        },
        {
          header      : " ",
          align  : "center",
          collapseTo: 'range_type',
          columns: [
              {
                header      : this.$t("list_reduction.table_range_type"),
                binding     : "range_type",
                minWidth    : getWidthByTextContent(this.$t("list_reduction.table_range_type")),
                maxWidth    : 860,
                allowSorting: false,
                isRequired  : false,
                cssClassAll : "btn-db",
                wordWrap    : true,
                multiLine   : true,
              },
          ],
        },
        {
          header      : "",
          header      : " ",
          align  : "center",
          collapseTo: 'last_modified_by',
          columns: [
              {
                header      : this.$t("list_reduction.table_last_modified_by"),
                binding     : "last_modified_by",
                minWidth    : getWidthByTextContent(this.$t("list_reduction.table_last_modified_by")),
                maxWidth    : 247,
                allowSorting: false,
                isRequired  : false,
                cssClassAll : "btn-db",
                multiLine   : true,
                wordWrap    : true,
              },
          ],
        },
        {
          header      : "",
          header      : " ",
          align  : "center",
          collapseTo: 'last_modified',
          columns: [
              {
                header      : this.$t("list_reduction.table_last_modified"),
                binding     : "last_modified",
                minWidth    : getWidthByTextContent(this.$t("list_reduction.table_last_modified")),
                width: '*',
                allowSorting: false,
                isRequired  : false,
                cssClassAll : "btn-db",
                multiLine   : true,
                wordWrap    : true,
              },
          ],
        },
      ];
    },
    scrollToTop() {
      if (!this.flexGrid) {
        return;
      }

      let rc                       = this.flexGrid.cells.getCellBoundingRect(0, 0, true);
      this.flexGrid.scrollPosition = new wjcCore.Point(this.flexGrid.scrollPosition.x, -rc.top);
    },
    onChangeFullScreen(isFullScreen) {
      if (isFullScreen) {
        this.scrollToTop();
      } else {
        this.initialView();

        this.$nextTick(() => {
          this.scrollToTop();
        });
      }
    },
    closePopup() {
      this.commentPopup = false;
      this.historyPopup = false;
    },
    async getListDuration() {
      await getDurations({ contractor_id: this.$route.query.duration }).then((res) => {
        this.isRedirectDuration = true
        const selectedDuration = res.data.find((duration) => duration.is_selected === true);
        this.duration          = `${moment.utc(selectedDuration?.start_at).format("YYYY/M")} - ${moment.utc(selectedDuration?.end_at).format("YYYY/M")}` || '';
      });
    },
    async onIdClicking(rowData) {
      if (!rowData.item?.id) return;
      // return
      // await Promise.all([
      //   this.updateDataScope(rowData.item.scope_id),
      //   this.updateDataCategory(rowData.item.category_id),
      //   this.updateDataMonth(rowData.item.month),
      //   this.updateIsApproved(rowData.item.approved_by === null ? false : true),
      //   this.updateDuration(rowData.item.duration_id),
      // ]);
      await updateDataBeforeRedirect(rowData.item);
      this.$router.push({ path: `${ROUTES.CREATE_REDUCTION_TARGET}?id=${rowData.item.id}` });
    },
    showRegisterPulldownEvent() {
      if (this.duration === '') {
        this.dialogNotification = true;
        return;
      }
      this.isShowMethodSelect = !this.isShowMethodSelect
      this.showRegisterPulldown = !this.showRegisterPulldown;
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
    onFlexGridInitialDone(flexGrid) {
      // flexGrid.mergeManager = new RestrictedMergeManager();
    }
  }
};
</script>
<style scoped lang="scss">
  @import './styles/index.scss';
  .template-detail-header {
    .wj-btn {
      display: none;
    }
  }

  .wj-flexgrid .wj-cell-maker {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $monoBlack;
    // font-family: 'Source Han Sans JP';
  }

  .wj-cell {
    .wj-cell-maker {
      background: none;
      justify-content: start;
    }

    .wj-cell-maker:hover {
      background: none;
      color: $monoBlack;
    }
  }

  .duration-wrapper {
    .duration-text {
      color: $monoBlack;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      margin-bottom: 8px;
    }

    .calculation-page {
      display: flex;
      align-items: center;
      background-color: $bgLight;
      padding: 15px;
      border-radius: 4px;
      height: 56px;

      div {
        margin-right: 20px;

        .duration-content {
          color: $monoBlack;
          font-weight: 700;
          font-size: 11px;
          line-height: 26px;
          letter-spacing: 0.03em;
          padding: 5px 0px 0px 0px;
        }

        .duration-text {
          color: $goldMid;
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 0.03em;
        }
      }
    }
  }

  .template-detail-header {
    .wj-cell-maker {
      margin-top: 2px;
      width: 26px;
      height: 20px;
      font-style: normal;
      // font-family: 'Source Han Sans JP';
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      color: $goldMid;
      text-align: center;
      background: none;
      border: 1px solid $goldLight;
    }

    .wj-cell-maker:hover {
      color: $monoWhite;
      background: #0072a3;
    }
  }
  .hide-filter {
    .wj-btn {
      display: none;
    }
  }
  .custom-table {
    padding-left: 20px !important;
  }
  .wrap {
    width: 100%;
    height: 100%;
    .wrap__block-container {
      padding: 0 0 44px;
    }

    &__status {
      margin-bottom: 48px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      padding-right: 0;

      @media (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        padding-right: 0px;
      }

      &__item {
        display: none;

        &__wrap {
          margin-left: 12px;

          p {
            color: $monoBlack;
            margin: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
            letter-spacing: 0.05em;

            &:nth-child(1) {
              font-style: normal;
              font-weight: 700;
              font-size: 11px;
              line-height: 18px;
              letter-spacing: 0.03em;
            }
          }
        }
      }

      .duration-wrapper {
        width: 100%;

        .duration {
          .calculation-page {
            justify-content: space-between;
          }
        }
      }

    .user-group-area
    .user-group-btn {
      font-size: 14px;
      color: #404d4d;
      font-weight:500;
      width:103px;
      height:40px;
    }
    }

    &__header {
      margin-bottom: 48px;
      margin-top: 40px;

      @media (max-width: $tablet) {
        margin-left: 0px;
      }

      &--p1 {
        max-width: 700px;
      }

      p {
        margin: 0px;
        font-weight: 400;
        font-size: 22px;
        line-height: 36px;
        letter-spacing: 0.05em;
        color: $goldMid;
        @media (max-width: 768px) {
          font-size: 22px;
          line-height: 28px;
        }
      }

      p:last-child {
        padding-top: 48px;
        color: $monoBlack !important;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 28px;
        letter-spacing: 0.05em;
      }
    }

    &__block {
      display: flex;
      flex-direction: column;
      padding: 0 0 40px;
      @media (max-width: 768px) {
        padding-left: 0px;
        flex-direction: column;
      }

      &__header {
        flex: 1 0;
        padding-bottom: 0px;

        &__content {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        span {
          margin: 0 16px 0 0;
          font-weight: 400;
          font-size: 22px;
          line-height: 36px;
          letter-spacing: 0.05em;
          color: $goldMid;
          @media (max-width: 768px) {
            font-size: 22px;
            line-height: 28px;
            margin-right: 8px;
          }
        }

        .helper-icon {
          img {
            margin-bottom: -2px
          }
        }
      }

      .wrap__status,
      .wrap__header {
        margin-bottom: 0px;
      }

      .button-icon {
        margin-right: 8px;
      }

      .btn-register {
        padding: 2px 10px;
      }

      .wrap__status__item {
        span {
          margin-left: 10px;
        }
      }
    }
  }
  .return {
    &__button-responsive {
      &.fixed-button-bottom {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        top: unset;
        .item-responsive {
          width: 90% !important;
        }
      }
      width: calc(100% + 40px);
      display: flex;
      flex-direction: column;
      background: $bgMid;
      box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
      padding: 16px 20px;
      position: relative;
      bottom: -80px;
      margin-left: -20px;
      .item-responsive {
        width: 100%;
        margin: 0 auto;
        .common-btn {
          width: 100% !important;
          .common-btn-text {
            margin-left: 10px;
          }
          .addregistericon_active {
            display: none;
          }
          .addregistericon_disabled {
            display: none;
          }
          // &:hover {
          //   .v-btn__content {
          //     .addregistericon {
          //       display: none !important;
          //     }
          //     .addregistericon_active {
          //       display: block !important;
          //     }
          //     .addregistericon_disabled {
          //       display: none;
          //     }
          //   }
          // }
          // &:active {
          //   .addregistericon {
          //     display: block;
          //   }
          //   .addregistericon_active {
          //     display: none;
          //   }
          //   .addregistericon_disabled {
          //     display: none;
          //   }
          // }
        }
        .register-button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &_warp {
            display: flex;
            // gap: 8px;
          }
          &_pulldown {
            transition: all 0.3s ease-out;
            .btn-icon {
              display: block;
            }
            .btn-icon_hover {
              display: none;
            }
            &.open-pulldown {
            transform: rotate(180deg);
            }
          }
          &:hover {
            background-color: $monoWhite !important;
            .register-button_warp {
              span {
                color: $monoBlack;
              }
            }
            .register-button_pulldown {
                .btn-icon {
                  display: none;
                }
                .btn-icon_hover {
                  display: block;
                }
              }
          }
        }
        &.register-btn {
        .register-pulldown {
          width: 100%;
          .pulldown-item {
            width: 100%;
          }
        }
      }
      }
    }
  }

  .register-btn {
    position: relative;
    .register-pulldown {
      position: absolute;
      width: 238px;
      max-height: 160px;
      background: $monoOffWhite;
      margin-top: 2px;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      z-index: 98;
      .item-pulldown {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 7px 0px 9px 16px;
        border: 1px solid rgba(42, 42, 48, 0.1);
        color: $monoBlack;
        border-bottom: 0;
        cursor: pointer;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-family: "Source Han Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        margin-left: unset !important;
        &:hover {
          background: $bgLight;
        }
      }
      .pulldown-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 7px 16px 9px;
        gap: 8px;
        width: 230px;
        height: 40px;
        border-bottom: 1px solid rgba(42, 42, 48, 0.1);
        cursor: pointer;
        &:last-child {
          border-bottom: unset;
        }
        &:hover {
          background: $bgLight;
        }
        .pulldown-text {
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.03em;
          color: $monoBlack;
        }
        &.disabled {
          pointer-events: none;
          cursor: default;
          background: $monoLight !important;
          .pulldown-text {
            color: $monoMid;
            font-weight: 500;
          }
        }
      }
    }
    .register-reduction-target-sm {
      justify-content: center !important;
    }
  }
  @include desktop {
    .wrap {
      &__header {
        margin-top: unset;
        margin-left: 40px;
      }

      &__status {
        padding-right: 40px;

        &__item {
          display: block;
        }
      }

      &__block {
        flex-direction: row;

        &__header {
          padding-bottom: 0;
        }
      }

      .wrap__block__header__content {
        display: flex;

        span {
          margin: 8px 10px 0px 0px;
        }

        // .helper-icon {
        //   margin: 16px 10px 0px 0px;
        // }
      }
      .wrap__status__item {
        .addregistericon_active {
          display: none;
        }
        .addregistericon_disabled {
          display: none;
        }
        .register-button {
          display: flex;
          align-items: center;
          width: 103px;
          &_warp {
            display: flex;
            // margin-right: 8px;
          }
          &_pulldown {
            transition: all 0.3s ease-out;
            .btn-icon {
              display: block;
            }
            .btn-icon_hover {
              display: none;
            }
            &.open-pulldown {
            transform: rotate(180deg);
            }
          }
          &:hover {
            background-color: $monoWhite !important;
            .v-btn__content {
              .register-button_warp {
                span {
                  color: $monoBlack;
                }
              }
              .register-button_pulldown {
                .btn-icon {
                  display: none;
                }
                .btn-icon_hover {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
  .return__header {
    background: $monoOffWhite;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    min-width: 103px!important;
    padding: 0!important;
    height: 40px;

    .register-button_warp {
      gap: 8px;
      .return-active {
        display: none;
      }
    }

    &:hover {
      .register-button_warp {
        .return {
          display: none;
        }
        .return-active {
          display: block;
        }
      }
      background: $goldLight;

      svg path {
        stroke: #ffffff;
      }

      .return__text {
        color: $monoOffWhite;
      }
    }

    &:active {
      .register-button_warp {
        .return {
          display: block;
        }
        .return-active {
          display: none;
        }
      }
    }

    .action {
      cursor: pointer;
    }

    .return__text {
      // font-family: 'Source Han Sans JP';
      color: #404d50;
      font-size: 14px;
      letter-spacing: 0.03em;
      font-style: normal;
      line-height: 24px;
      font-weight: 500;
    }
  }
  .template-register-button.disabled {
    pointer-events: none;
    cursor: default;
    background: $monoLight !important;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24) !important;

    .v-btn__content {
      span {
        color: $monoMid !important;
      }
      .addregistericon {
        display: none !important;
      }
      .addregistericon_active {
        display: none;
      }
      .addregistericon_disabled {
        display: block;
      }
    }
  }
  @include desktop {
    .custom-table {
      padding-left: 0 !important;
    }
    .return {
      &__header {
        margin-top: unset;
      }
    }
    .return__button-responsive {
      display: none;
    }
    .wrap {
      .wrap__block__header__content {
        .helper-icon {
          &.tooltip {
            position: absolute;
            width: 400px;
            max-width: unset;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .wrap__status
    .user-group-area
    .user-group-btn {
      margin-bottom: 0px !important;
    }
  }

  //custome style
  @media screen and (min-width: 1024px) and (max-width: 1150px) {
    .wrap {
      &__block {
        flex-direction: column;

        &__header {
          padding-bottom: 58px;
        }
      }

      //&__status {
      //  padding-right: 0;
      //}
    }
  }
  </style>
<style lang="scss">
.table-reduction-target-list {
  .wj-elem-collapse {
    position: absolute;
    width: 20px;
    height: 20px;
    left: 9px;
    top: 9px;
    background: rgba(121, 134, 134, 0.12);
    border-radius: 6px;
    margin-right: 4px;
  }
  .wj-elem-collapse {
    &.wj-glyph-minus {
      color: $monoDark;
      border-top: 1px solid;
    }
  }
  .wj-cell.merge-rows {
    border-bottom: unset !important;
  }
}
</style>


